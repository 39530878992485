<template>
    <v-container grid-list-md pa-0 relative>
        <v-layout row wrap>
            <v-flex xs12 sm12>
                <v-card outlined class="primary--border">
                    <v-card-title class="title">
                        Advances Journal
                        <v-spacer></v-spacer>
                    </v-card-title>
                    <v-data-table 
                        :headers="headers"
                        :items="form.items.data"
                        :single-expand="singleExpand"
                        sort-by="id"
                        :expanded.sync="expanded"
                        show-expand
                        item-key="amount"
                        :options.sync="pagination"
                        :footer-props="footerProps"
                        :server-items-length="form.items.meta.total"
                        :search="search"
                        :loading="form.loading"
                    >

<!--                        <template v-slot:item="{index, item}">-->
<!--                            <tr :class="'highlight'">-->
<!--                                <td class="text-xs-left">-->
<!--                                    {{index +1}}-->
<!--                                </td>-->
<!--                                <td class="text-xs-left">{{ item.transaction_date }}-->

<!--                                <td class="text-xs-left">{{ item.amount }} (Dr)-->
<!--                                </td>-->
<!--                                <td class="text-xs-right">-->
<!--                                    <v-btn v-if="!item.is_journal_entered" small icon color="primary"-->
<!--                                           class="pa-0 ma-1">-->
<!--                                        <v-icon dark>crop_free</v-icon>-->
<!--                                    </v-btn>-->
<!--                                    <v-btn color="success"-->
<!--                                           v-else-if="item.is_journal_entered && item.is_journal_entered===1" small icon-->
<!--                                           class="pa-0 ma-1">-->
<!--                                        <v-icon>check</v-icon>-->
<!--                                    </v-btn>-->
<!--                                </td>-->
<!--                            </tr>-->
<!--                        </template>-->
                        <template v-slot:expanded-item="{ headers, item }">
                            <td :colspan="headers.length">
                                <v-card v-if="item.details.length > 0" style="width: 100%;">
                                    <table width="100%" class="inner-table">
                                        <thead>
                                        <tr>
                                            <th class="text-xs-left"><strong>Fee Head</strong></th>
                                            <th class="text-xs-left"><strong>Cr</strong></th>
                                        </tr>
                                        <tr v-for="(x,i)  in item.details" :key="i" style="height: 0px">
                                            <td class="text-xs-left">{{x.fee_head}}
                                                <p style="color: red; font-style: italic">({{x.asset_ledger_head}})</p>
                                            </td>
                                            <td class="text-xs-left">{{x.sum_amount}}</td>
                                        </tr>
                                        </thead>
                                        <tfoot v-if="item.details.length > 0">
                                        <tr>
                                            <td :colspan="headers.length" style="text-align: right;">
                                                <v-btn v-if="!item.is_journal_entered" small color="success"
                                                       outlined @click="advanceJournal(props.item)">
                                                    Post to account
                                                </v-btn>
                                            </td>
                                        </tr>
                                        </tfoot>
                                    </table>
                                </v-card>
                                <v-card v-else style="margin-left: 30px">
                                    No Data Found
                                </v-card>
                            </td>
                        </template>
                        <template v-slot:item.action="{ item }">
                            <v-btn v-if="!item.is_journal_entered" small icon color="primary"
                                   class="pa-0 ma-1">
                                <v-icon dark>crop_free</v-icon>
                            </v-btn>
                            <v-btn color="success"
                                   v-else-if="item.is_journal_entered && item.is_journal_entered===1" small icon
                                   class="pa-0 ma-1">
                                <v-icon>check</v-icon>
                            </v-btn>
                        </template>

                    </v-data-table>
                </v-card>
            </v-flex>
            <v-dialog v-model="reasonDialog" persistent max-width="290">
                <v-card>
                    <v-card-title class="title pa-3">
                        <v-textarea outlined name="input-7-4" label="Reason" v-model="reason"></v-textarea>
                    </v-card-title>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="success" outlined small @click="reasonDialog = false,details = []">Cancel</v-btn>
                        <v-btn :disabled="!reason" color="warning" outlined small @click="advanceJournalPost">Post To
                            Account
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-layout>
    </v-container>
</template>
<script>
    import {mapState} from 'vuex'
    import Form from '@/library/Form'
    import Mixins from '@/library/Mixins';

    export default {
        mixins: [Mixins],
        data: () => ({
            gradeLoading: false,
            form: new Form({
                amount: '',
                enroll_code: '',
                stat: 0,
            }, '/api/advance-journal'),
            details: [],
            expanded: [],
            singleExpand:true,
            headers: [
                {text: '#', align: 'left', value: 'id', sortable: false},
                {text: 'Transaction Date', align: 'left', value: 'transaction_date', sortable: false},
                {text: 'Advance Amount', align: 'left', value: 'amount', sortable: false},
                {text: 'Action', align: 'right', value: 'action', sortable: false},
            ],
            expand: false,
            search: null,
            reasonDialog: false,
            reason: '',
            verify: 0,
            unverify: 0,
            accountServiceStatus: false,
            createJournal: false,
            alreadyJournal: false,
            totalAmount: 0,
            narration: '',
            advances: [],
            payStatus: 0,

        }),

        computed: {
            ...mapState(['batch']),
        },

        mounted() {
            if (this.$auth.getServices('ACCOUNT')) this.accountServiceStatus = true;
            else this.accountServiceStatus = false;
        },

        watch: {
            'pagination': function () {
                this.get();
            },
        },

        methods: {
            queryString() {
                let json = this.pagination;
                return '?' +
                    Object.keys(json).map(function (key) {
                        if (![null, undefined].includes(json[key]))
                            return encodeURIComponent(key) + '=' + encodeURIComponent(json[key]);
                    }).join('&');
            },

            get(params) {
                let query = [null, undefined].includes(params) ? this.queryString() : params;
                this.form.get(null, query).then(({data}) => {
                })
            },
            advanceJournal(item) {
                this.reasonDialog = true;
                this.details = item;
            },
            advanceJournalPost(item) {
                // console.log({...this.details, narration: this.reason});
                this.$rest.post('/api/journal-entry/advance', {
                    ...this.details,
                    narration: this.reason
                }).then(({res}) => {
                    this.reasonDialog = false;
                    this.get();
                });
            }

        }
    }
</script>
<style lang="scss">
</style>